import { Dispatch, SetStateAction } from "react";
import moment from "moment";

export type SeasonsClosest =
  | "summer"
  | "autumn"
  | "winter"
  | "valentine"
  | "spring2023"
  | "summer2023"
  | "autumn2023"
  | "winter2023"
  | "spring2024"
  | "summer2024"
  | "autumn2024"
  | "spring2025"
  | null;

const isBrowser = () => typeof window !== "undefined";

const detectMob = (includeTablet = true) => {
  const userAgent = navigator.userAgent;
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent.toLowerCase()
    );
  return (
    (toMatch.some((toMatchItem) => {
      return userAgent.match(toMatchItem);
    }) ||
      (userAgent.includes("Mac") &&
        "ontouchend" in document &&
        includeTablet)) &&
    (includeTablet || !isTablet)
  );
};

const detectIsSeasonDuring = (seasons: any[][]) => {
  const currentTime = new Date().getTime();
  seasons.forEach((season) => {
    const [seasonStartDate, seasonEndDate, setSeasonIsDuring] = season;
    const seasonStartTime = seasonStartDate
      ? new Date(seasonStartDate).getTime()
      : null;
    const seasonEndTime = new Date(seasonEndDate).getTime();
    setSeasonIsDuring(
      seasonStartTime
        ? currentTime > seasonStartTime && currentTime < seasonEndTime
        : currentTime < seasonEndTime
    );
  });
};

const checkSeasonClosest = (
  seasons: [string, SeasonsClosest][],
  setClosestSeason: Dispatch<SetStateAction<SeasonsClosest>>
) => {
  const closestSeason = seasons.reduce(
    (prev, next) => {
      const prevSeasonEnd = prev[0] ? moment(prev[0]) : moment();
      const nextSeasonEnd = moment(next[0]);
      return (!prev[0] || nextSeasonEnd.isAfter(prevSeasonEnd)) &&
        nextSeasonEnd.isBefore(moment())
        ? next
        : prev;
    },
    ["", null]
  );
  setClosestSeason(closestSeason[1]);
};

export { isBrowser, detectMob, detectIsSeasonDuring, checkSeasonClosest };
