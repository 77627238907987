module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/jp-tyo-white-label-coffee-reactjs-order-app/jp-tyo-white-label-coffee-reactjs-order-app/src/components/WrapPageElementLayout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ja","en","kr","cn","tw"],"defaultLanguage":"ja","siteUrl":"https://tag-coffee-stand.jp","trailingSlash":"always","pages":[{"matchPath":"/:lang?/large-order","excludeLanguages":["en","kr","cn","tw"]}],"i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"tracesSampleRate":0.05,"attachStacktrace":true,"debug":false,"dsn":"https://6318b65052f048bd93006b1ab7a47465@o1057824.ingest.sentry.io/6044863","initialScope":{"tags":{"system":"Order App"}},"browserTracingOptions":{"tracingOrigins":["https://tag-coffee-stand.jp"]},"release":"TAG-Order-App_2025-03-04"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MR55G48","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TAG COFFEE STAN(D)","short_name":"TAG COFFEE STAN(D)","description":"TAG COFFEE STAN(D)は、ドリンクとラベルを自分らしくカスタマイズできるコーヒーサービスです","start_url":"/","display":"standalone","crossOrigin":"anonymous","icons":[{"src":"/favicon/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicon/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicon/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicon/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
